import React from "react";
import Layout from "../components/layout/MainLayout";
import { Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import Sidebar from "../components/routes/Home/News/Sidebar/Sidebar";
import MainColumn from "../components/routes/Home/News/MainColumns/MainColumn";
import { Crumbs } from "../components/common/ntmwd/elements/Crumbs";
import get from "lodash/get";
import { slugify } from "../components/common/MediaGallery/util/tagging";
import { SEO } from "wmk-lib";
import { siteTitle, subdomainUrl, twitter } from "../vars/helpers";

const IndexPage = ({ pageContext, data }) => {
  const { feed } = data;
  const tagTitle = get(data, "tag.name");
  const tag = { title: tagTitle, slug: slugify(tagTitle) };
  const { title, slug } = tag;
  return (
    <Layout>
      <Container>
        <SEO
          baseUrl={subdomainUrl}
          title={"Tag: " + title}
          siteTitle={siteTitle + " Photos & Videos"}
          slug={`tags/${slug}`}
          twitterHandle={twitter}
          twitterImage={null}
          ogImage={null}
        />
        <Row>
          <Crumbs
            breadcrumbs={[
              { title: "Home", to: "https://ntmwd.com/", target: "self" },
              { title: "News Room", to: "/" },
              { title: "Tags", to: "/tags" },
              { title, to: `/tags/${slug}` },
            ]}
          />
        </Row>
        <Row>
          <MainColumn
            sections={[
              {
                title: "Tag: " + title,
                posts: feed.edges.map((e) => e.node),
                loadMore: 5,
                tag,
              },
            ]}
          />
          <Sidebar />
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query tagQuery($slug: String!) {
    tag: wpTag(slug: { eq: $slug }) {
      name
    }
    feed: allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...NodePostFields
        }
      }
    }
  }
`;
